import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import ImagenCliente from '../../components/ImagenCliente'
import imagenes from '../../constants/imagenes'

const Clientes = () => {
  return (
    <Col xs={12}>
      <Row id='fila-clientes'>
        <Col xs={12}>
          <Row id='caja-clientes'>
            <Col xs={12} lg={4} className='justify-content-center align-items-center px-4 pt-4 pb-3 pr-lg-5 py-lg-5'>
              <h1 className='header pl-lg-3 pb-lg-2 pt-lg-4'>{Dictionary.clients}</h1>   
            </Col>
            <Col xs={12} lg={8} className='px-5 pt-2 pb-5 p-lg-5'>
              <Row className='align-items-center justify-content-center logos-clientes'>
                {
                  imagenes.map(imagen => {
                    return (
                      <ImagenCliente key={`img-${imagen.imagen}`} imagen={imagen.imagen} widthImage={imagen.ancho} />
                    )
                  })
                }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const ClientesContainer = () => {
  return (
    <RowContenedor
      tipo='clientes'
      componente={Clientes}
    />
  )
}

export default ClientesContainer
