import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import Dictionary from '../Dictionary'
import Logo from '../../assets/images/img-web/navbar_logo.jpg'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const NavBarComponent = (props) => {
  const { onClick } = props
  return (
    <Navbar collapseOnSelect expand='lg' variant='dark' className='w-100 px-3  grism' id='custom-navbar'>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Brand>
        <Link to='/app/webroot/seat/inicio' onClick={onClick} className='d-lg-block ml-lg-4 d-none'>
          <img src={Logo} alt='logo' className='logo-navbar d-inline-block align-top' />
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse id='navbarSupportedContent'>
        <Nav className='ml-auto'>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/inicio' onClick={onClick}>{Dictionary.index}</NavLink>
          <span className='my-2 mx-4 d-none d-lg-block'>|</span>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/tipos-plataforma' onClick={onClick}>{Dictionary.typesPlatform}</NavLink>
          <span className='my-2 mx-4 d-none d-lg-block'>|</span>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/que-es' onClick={onClick}>{Dictionary.whatIs}</NavLink>
          <span className='my-2 mx-4 d-none d-lg-block'>|</span>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/licenciamiento' onClick={onClick}>{Dictionary.licence}</NavLink>
          <span className='my-2 mx-4 d-none d-lg-block'>|</span>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/caracteristicas' onClick={onClick}>{Dictionary.characteristics}</NavLink>
          <span className='my-2 mx-4 d-none d-lg-block'>|</span>
          <NavLink className='nav-link' activeClassName='active' to='/app/webroot/seat/contacto' onClick={onClick}>{Dictionary.contact}</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
} 

export default NavBarComponent
