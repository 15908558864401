import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import Parrafo from '../../components/Parrafo'

const TiposPlatamorma = () => {
  const lineasDataIntegration = [Dictionary.dataIntegration_1, Dictionary.dataIntegration_2 ]
  const lineasWorkflow = [Dictionary.workflow_1, Dictionary.workflow_2]
  const lineasAudit = [Dictionary.audit_1, Dictionary.audit_2]
  const lineasBuildInfo = [Dictionary.buildInfo_1, Dictionary.buildInfo_2]
  const lineasTypesPlatform = [Dictionary.typesPlatform_1, Dictionary.typesPlatform_2, Dictionary.typesPlatform_3]
  return (
    <Row className='align-items-end' id='fila-plataforma'>
      <Col xs={12}>
        <Row className='justify-content-center caja-container' id='caja-plataforma'>
          <h1 className="mt-3">{Dictionary.titleTypesPlatform}</h1>
          <Parrafo
            subtitulo={Dictionary.dataIntegrationPlathform}
            lineas={lineasDataIntegration}
          />
          <Parrafo
            subtitulo={Dictionary.workflowPlathform}
            lineas={lineasWorkflow}
          />
          <Parrafo
            subtitulo={Dictionary.auditPlathform}
            lineas={lineasAudit}
          />
          <Parrafo
            subtitulo={Dictionary.buildInfoPlathform}
            lineas={lineasBuildInfo}
          />
          <Parrafo lineas={lineasTypesPlatform} />
        </Row>
      </Col>
    </Row>
  )
}

const TiposPlatamormaContainer = () => {
  return (
    <RowContenedor
      tipo='plataforma'
      componente={TiposPlatamorma}
    />
  )
}

export default TiposPlatamormaContainer