import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'

const Contacto = () => {
  return (
    <Row className='align-items-end' id='fila-contacto'>
      <Col xs={12}>
        <Row className='justify-content-center caja-container grism' id='caja-contacto'>
          <h1 className='mt-3'>{Dictionary.contactForVisit}</h1>
          <p className='texto-presentacion px-4 mt-3'>
            <br />{Dictionary.contact_1}
            <br />Contáctenos al correo <a style={{color: 'white'}} href='mailto:dire@binarybag.com'>dire@binarybag.com</a> y un product manager lo contactará y le brindará la asesoría y guía necesaria para resolver sus problemas de adquisición y procesamiento de datos con la solución más sofisticada, liviana y versátil disponible en el mercado nacional.                      
          </p>
        </Row>
      </Col>
    </Row>
  )
}

const ContactoContainer = () => {
  return (
    <RowContenedor
      tipo='contacto'
      componente={Contacto}
    />
  )
}

export default ContactoContainer
