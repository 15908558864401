import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import NavBarLocal from '../NavBar'
import SwitchLocal from './switchRouter'
import NavBarGrisM from '../../grismonitor/components/NavBar'
import SwitchGrisM from '../../grismonitor/components/AppRouter/switchRouter'

const AppRouter = (props) => {
  const [inProp, setInProp] = useState(false);
  const [esGrisM, setEsGrisM] = useState(false);
  const { pathname } = window.location

  useEffect(() => {
    const pathGris = pathname.includes('app/webroot/seat')
    setEsGrisM(pathGris)
    document.title = pathGris ? 'BinaryBag - GrisMonitor' : 'BinaryBag'
  },[pathname])
  return (
    <Router>
      <div className='tab-content'>
        {esGrisM && ( 
          <NavBarGrisM onClick={() => setInProp(!inProp)} />
        )}
        {!esGrisM && (
          <NavBarLocal onClick={() => setInProp(!inProp)} />
        )}
        <CSSTransition
          in={inProp}
          timeout={180}
          classNames='my-view'
        >
          <div className='containers'>
            {esGrisM && (
              <SwitchGrisM {...props} />
            )}
            {!esGrisM && (
              <SwitchLocal {...props} />
            )}
          </div>
        </CSSTransition>
      </div>
    </Router>
  )
}

export default AppRouter
