import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import CajaTituloIzquierda from '../../components/CajaTituloIzquierda'

const QuienesSomos = () => {
  return (
    <Col xs={12}>
      <Row id='fila-quienessomos'>
        <CajaTituloIzquierda
          titulo={Dictionary.who_we_are}
          idCaja='quienessomos'
          classCaja='pt-4 pb-3 p-lg-5'
          classHeader='pl-lg-5'
        />
        <Col xs={12} lg={7} className='p-lg-4' id='caja-texto-quienessomos'>
          <Row>
          <Col xs={12}>
            <Row>
            <Col xs={12} className='py-3 py-lg-5'>
                <p className='px-2 mx-3 sin-nada'>
                  <span className='quienes-somos'>{Dictionary.we_are}</span>
                </p> <br />
                <p className='px-2 mx-3 borde-izquierda'>
                <span className='quienes-somos'>{Dictionary.mission}</span> <br />
                  <span className='mision-vision'>{Dictionary.our_mission}</span><br />
                </p>
                <p className='px-2 mx-3 borde-izquierda'>
                  <span className='quienes-somos'>{Dictionary.vision}</span> <br />
                  <span className='mision-vision'>{Dictionary.our_vision}</span><br />
                </p>
                <p className='px-2 mx-3 borde-izquierda'>
                  <span className='quienes-somos'>{Dictionary.policy}</span> <br />
                  <span className='mision-vision'>{Dictionary.quality_policy}</span><br />
                </p>
              </Col>
            </Row>
          </Col>
          </Row>
        </Col>
      </Row>
    </Col>

    
  
  )
}


const QuienesSomosContainer = () => {
  return (
      <RowContenedor
        tipo='quienessomos'
        componente={QuienesSomos}
      />
  )
}

export default QuienesSomosContainer
