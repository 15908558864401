import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
const Inicio = () => {
  return (
    <Row className='align-items-end' id='fila-presentacion'>
      <Col lg={12} xs={12} className='order-lg-2'>
        <Row className='justify-content-center caja-container' id='caja-presentacion'>
          <h1 className='mt-3'>{Dictionary.technicalInformation}</h1>
          <p className='texto-presentacion px-4 mt-3'>
            {Dictionary.descriptionTechnicalInformation}
          </p>
          <h1 className='mt-3'>{Dictionary.grismonitor_v1}</h1>
          <p className='texto-presentacion px-4'>
            {Dictionary.descriptionGrismonitor_v1}
          </p>
        </Row>
      </Col>
    </Row>
  )
}

const InicioContainer = () => {
  return (
    <RowContenedor
      tipo='presentacion'
      componente={Inicio}
    />
  )
}

export default InicioContainer
