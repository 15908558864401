import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'

const QueHacemos = () => {
  return (
    <Col xs={12}>
      <Row id='fila-quehacemos'>
        <Col xs={12}>
          <Row className='justify-content-center' id='caja-quehacemos'>
            <Col xs={12} lg={7} className='d-flex justify-content-center align-items-center px-4 pt-4 pb-3 p-lg-4'>
              <h1 className='header'>{Dictionary.what_we_do}</h1>
            </Col>
            <Col xs={12} lg={5}>
              <Row>
                <p className='texto px-2 mx-3 my-3 my-lg-5 borde-izquierda'>{Dictionary.our_paradigm}</p>
              </Row>
              <Row>
                <p className='texto px-2 mx-3 my-3 my-lg-5 borde-izquierda'>{Dictionary.our_consideration}</p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const QueHacemosContainer = () => {
  return (
    <RowContenedor
      tipo='quehacemos'
      componente={QueHacemos}
    />
  )
}

export default QueHacemosContainer
