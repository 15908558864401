import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import Parrafo from '../../components/Parrafo'

const Caracteristicas = () => {
  const lineasCaracteristicas = [
    Dictionary.characteristics_1,
    Dictionary.characteristics_2,
    Dictionary.characteristics_3,
    Dictionary.characteristics_4,
    '',
    Dictionary.characteristics_5,
    Dictionary.characteristics_6,
    '',
    Dictionary.characteristics_7,
    Dictionary.characteristics_7_1,
    Dictionary.characteristics_7_2,
    Dictionary.characteristics_7_3,
    Dictionary.characteristics_7_4,
    Dictionary.characteristics_7_5,
    Dictionary.characteristics_7_6,
  ]
  return (
    <Row className='align-items-end' id='fila-caracteristicas'>
      <Col xs={12}>
        <Row className='justify-content-center caja-container' id='caja-caracteristicas'>
          <h1 className='mt-3'>{Dictionary.characteristicsUse}</h1>
          <Parrafo lineas={lineasCaracteristicas} />
        </Row>
      </Col>
    </Row>
  )
}

const CaracteristicasContainer = () => {
  return (
    <RowContenedor
      tipo='caracteristicas'
      componente={Caracteristicas}
    />
  )
}

export default CaracteristicasContainer
