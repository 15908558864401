export default {
  es: {
    index: 'INICIO',
    what_we_do: 'QUÉ HACEMOS',
    who_we_are: 'QUIÉNES SOMOS',
    mission: 'MISIÓN',
    vision: 'VISIÓN',
    clients: 'CLIENTES',
    policy: 'POLÍTICA DE CALIDAD',
    contact: 'CONTACTO',
    welcome_to: 'Bienvenido a',
    we_are: 'Somos una empresa de ingeniería de sistemas con un alto sentido de la responsabilidad, dedicada a la solución de problemas complejos.',
    our_paradigm: 'Nuestro paradigma considera que todo sistema de información que constituye una solución tecnológica, debe incluir la conjunción de elementos de datos, procesos, reglas e interfaces.',
    our_consideration: 'Se consideran como condicionantes para la solución tecnológica los roles de usuario, sus permisos y toda otra restricción en general que se realice sobre el caso a resolver (arquitectura, infraestructura, ámbito, volumen de transacciones, seguridad, aspectos legales, etc).',
    our_mission:'BinaryBag es una empresa dedicada al desarrollo de software a la medida de nuestros clientes, que solucionen de manera eficaz y eficiente sus problemas y necesidades, agregamos valor y acompañamos el desarrollo y evolución de nuestros clientes con lealtad y compromiso.',
    our_vision: 'Queremos ser el partner tecnológico de nuestros clientes, ser parte de la solución de sus desafíos tecnológicos de manera eficaz. Atraer talentos capaces de mantener desafíos tecnológicos permanentes. Innovar es nuestro propósito. Resolver problemas complejos, nuestra motivación.',
    quality_policy: 'Buscamos la confianza y satisfacción de nuestro cliente, manteniendo conductas que propicien el cumplimiento de objetivos y compromisos. Como equipo debemos estar capacitados y complementarnos de manera efectiva, buscando mejorar continuamente en nuestra labor. Estas declaraciones son pilares fundamentales para desarrollar sistemas eficientes y que aporten a nuestros clientes.',
    partner: 'SOCIO',
    ivan_beltrand: 'IVÁN BELTRAND',
    jorge_aravena: 'JORGE ARAVENA',
    name: 'Nombre',
    email: 'Email',
    about: 'Tema',
    message: 'Mensaje',
    send: 'ENVIAR'
  }
}