import React from 'react'
import { Route, Switch } from 'react-router-dom'

import InicioContainer from '../../containers/InicioContainer'
import QueHacemosContainer from '../../containers/QueHacemosContainer'
import QuienesSomosContainer from '../../containers/QuienesSomosContainer'
import ClientesContainer from '../../containers/ClientesContainer'
import ContactoContainer from '../../containers/ContactoContainer'

const Inicio = () => <InicioContainer />
const QueHacemos = () => <QueHacemosContainer />
const QuienesSomos = () => <QuienesSomosContainer />
const Clientes = () => <ClientesContainer />
const Contacto = () => <ContactoContainer />

const SwithRouter = () => {
  return (
    <Switch>
      <Route path='/' exact component={Inicio} />
      <Route path='/inicio' exact component={Inicio} />
      <Route path='/que-hacemos' exact component={QueHacemos} />
      <Route path='/quienes-somos' exact component={QuienesSomos} />
      <Route path='/clientes' exact component={Clientes} />
      <Route path='/contacto' exact component={Contacto} />
      <Route component={Inicio} />
    </Switch>
  )
}

export default SwithRouter
