export default {
  es: {
    index: 'INICIO',
    typesPlatform: 'TIPOS DE PLATAFORMA',
    whatIs: 'QUÉ ES',
    licence: 'LICENCIAMIENTO',
    characteristics: 'CARACTERÍSTICAS',
    contact: 'CONTACTO',
    technicalInformation: 'Ficha Técnica Grismonitor',
    descriptionTechnicalInformation: 'Herramienta de software para la construcción de plataformas web basadas en el paradigma captura, validación, proceso y publicación',
    grismonitor_v1: 'GrisMonitor v1.0',
    descriptionGrismonitor_v1: 'GrisMonitor es un potente conjunto de módulos de software para la implementación de plataformas tecnológicas que requieran la creación de procesos que tienen como base la captura, validación, procesamiento y publicación de datos estructurados y no estructurados provenientes ya sea de productores de información o de diversas fuentes de información internas o externas a la organización.',
    titleTypesPlatform: 'Tipos de plataformas que se pueden crear con GrisMonitor',
    dataIntegrationPlathform: 'Plataformas de integración de datos',
    dataIntegration_1: 'Las plataformas de integración de datos tienen el rol de homologar datos de distintos sistemas, automatizar carga periódica, validar, almacenar y concentrar en una capa común toda la información de interés que las unidades de negocio y gestión de las organizaciones requieran. En este punto el caso a resolver es el de “Silos de información” en el cual las empresas resuelven cada necesidad de negocio con sistemas puntuales y aislados que no necesariamente conversan entre sí. Para implementar la capa de integración es necesario contar con datos comunes, sin embargo suele suceder que los datos teóricamente comunes, no conservan el mismo formato o emplazamiento entre sistemas, lo que dificulta la integración y agregación de estos entre sistemas.',
    dataIntegration_2: 'GrisMonitor cuenta con elementos de software que permiten definir las transformaciones necesarias en los datos capturados de forma tal que sea posible agrupar los datos capturados de manera estándar. Estos datos son incorporados a una siguiente capa de datos que permite su visualización y publicación a otros sistemas como por ejemplo la web institucional.',
    workflowPlathform: 'Plataformas basadas en Workflow',
    workflow_1: 'Plataformas basadas en Workflow son aquellas que relacionan documentos o archivos de diversa índole con labores que deben realizar distintos actores en el curso de los distintos ciclos de negocio de una empresa. Típicamente el elemento a tratar es un documento o conjunto de datos (archivo) sobre el cual van sucediendo diversos cambios de estado, que están definidos y acotados por el proceso de negocio declarado. Estos cambios de estado son realizados por usuarios o por otros procesos, incluso puede tratarse de procesos automáticos que dependen de factores como el transcurso de un cierto periodo por ejemplo.',
    workflow_2: 'GrisMonitor cuenta con un módulo de abstracción de procesos que permite configurar completos Workflows en base a encadenamientos sucesivos de ciclos del tipo “entrada-tarea-salida” con atributos como “plazo y responsable” y condiciones externas dadas por reglas lógico-algebraicas.',
    auditPlathform: 'Plataformas de Auditoria',
    audit_1: 'En los procesos de auditoria y supervisión en general se requiere la lectura de distintos orígenes de datos, clasificados en grupos objetivos de información que deben ser revisados en base a un conjunto de reglas especiales y que en ocasiones poseen una complejidad superior. Si a esta situación se suma que los datos a procesar consisten en un gran volumen, el problema de auditoria se torna en ocasiones una tarea titánica.',
    audit_2: 'GrisMonitor cuenta con un módulo de abstracción de grupos de datos o archivos que permite el mapeo de los mismos en una estructura de datos, para luego capturarlos y almacenarlos en la base de datos extraída de la estructura generada. Posteriormente se pueden construir diversos agrupadores de datos e indicadores, que pueden ser evaluados según las reglas que se requieran en la auditoria señalada.',
    buildInfoPlathform: 'Plataformas para construcción y despliegue de Indicadores',
    buildInfo_1: 'El caso de las plataformas de indicadores es conocido como paneles de información o Dashboard, estas plataformas típicamente se encargan de desplegar datos de consumo final para los distintos casos de negocio en las organizaciones. ',
    buildInfo_2: 'GrisMonitor cuenta con un módulo constructor de publicaciones que genera como salida una capa de software que facilita la publicación de resultados según la forma y estructura que defina el cliente.',
    typesPlatform_1: 'En definitiva Binarybag ha desarrollado un potente sistema bajo el paradigma de captura, procesamiento y publicación de datos que puede ser utilizado en un amplio abanico de posibilidades para el tratamiento de la información desde la perspectiva operacional, analítica y de gestión. Los usos de este tipo de sistema van desde la simple captura que todo sistema realiza, hasta amplios y complejos sistemas normativos, que incorporen soluciones orientadas en última instancia a la ciencia de datos, para aplicaciones que el cliente estime conveniente.',  
    typesPlatform_2: 'Diversos organismos pueden beneficiarse de las prestaciones de esta herramienta de software, que relaciona datos e información respecto de un fenómeno o familia de procesos específicos, que deben cumplir una serie de reglas de negocio, simplificando y agilizando la gestión de la información y el proceso de negocio.',
    typesPlatform_3: 'También es posible realizar la integración de este software de servidor con otros sistemas ya sea vía API REST, WebServices o por medio de bases de datos accesibles entre sí.',
    titleWhatIs: 'Qué es GRISMonitor',
    whatIs_1: 'GrisMonitor está compuesto de 5 módulos de software y una serie de librerías de apoyo que pueden ser utilizados en conjunto o separadamente, según sea la necesidad del sistema a emplazar.',    
    whatIs_2: 'GRISMonitor = <b>G</b>uiaFlow + <b>R</b>esult + <b>I</b>ntelicon + <b>S</b>keleton + <b>Monitor</b>',    
    whatIs_3: 'Estos elementos permiten generar ciertas piezas de software para sistemas web que pueden complementar otras soluciones de software mediante API REST.',      
    whatIs_4: 'A continuación se describen brevemente cada uno de estos elementos.',
    moduleFlow: 'Módulo GuiaFlow',
    moduleFlow_1: 'Este módulo permite la creación de flujos mediante el paradigma “entrada-tarea-salida”. Esto implica la definición de una tarea como un conjunto de acciones que operan sobre una entrada de datos. El resultado de estas acciones genera una salida. ',
    moduleFlow_2: 'Este módulo debe ser configurado a partir de la definición de las distintas tareas de un proceso para posteriormente enlazarlos o vincularlos en grupos que constituyen las actividades del proceso. A nivel de proceso se deben definir las etapas y actores que inciden sobre el sistema, definiendo los permisos y estados que se esperan de la acción de estos elementos sobre las actividades o tareas.',
    moduleFlow_3: 'El vinculo entre tareas se define como datos preprocesados que pueden ser datos como insumo de la tarea o datos de control de la tarea. Por ejemplo, un dato puede ser una lista de números que entran a una tarea que contiene un algoritmo de validación de ciertos cálculos que se realizan sobre esos números. La salida será los datos calculados y un indicador de estado que permita saber el resultado de la validación.',
    moduleFlow_4: 'Finalmente se debe realizar el deploy de los flujos creados, generando de esta manera un sistema que realiza los procesos modelados. El sistema generado no esta sujeto a restricciones de licencia y será de propiedad del cliente.',
    moduleFlow_5: 'Es importante señalar que este sistema generado es de código abierto, vale decir que el cliente podrá realizar codificación sobre lo generado, obteniendo así un sistema mucho más detallado y especifico.',
    moduleResult: 'Módulo Result',
    moduleResult_1: 'El modulo Result permite la construcción de elementos de publicación e interfaces. Estas interfaces pueden ser:',
    moduleResult_1_1: '•	Menús',
    moduleResult_1_2: '•	Formularios',
    moduleResult_1_3: '•	Fichas ',
    moduleResult_1_4: '•	Reportes',
    moduleResult_1_5: '•	Gráficos ',
    moduleResult_1_6: '•	Dashboard',
    interfaces: ['Menús','Formularios','Fichas','Reportes','Gráficos','Dashboard'],
    moduleResult_2: 'El módulo Result requiere como insumo de entrada una conexión a una fuente de datos para publicación en donde se definen los datos y los detalles de la interfaz a desplegar. Eventualmente esta interfaz generara datos adicionales como los inputs de un formulario, datos de control como las veces que fue desplegado o quien lo visitó o puede ser también la generación del resultado en pdf que podría quedar guardado en una base de datos.',
    moduleResult_3: 'Esta herramienta en su instancia de diseño permite configurar las distintas interfaces separadas en clases según su tipo, relacionadas mediante un menú y definidas en archivos de texto.',
    moduleResult_4: 'Una vez configuradas las distintas interfaces, se debe proceder al deploy que genera como resultado un sistema básico de publicación de resultados.',
    moduleResult_5: 'Lo relevante de este y otros módulos es que el código generado es abierto, permitiendo al cliente la elaboración de nuevo código sobre el sistema generado.',
    moduleIntelicon: 'Módulo Intelicon',
    moduleIntelicon_1: 'El modulo Intelicon permite la construcción de reglas de validación y reglas de comportamiento, es decir reglas para ser aplicadas a las entradas de datos y reglas de composición que se aplican en la salida de datos.',
    moduleIntelicon_2: 'El módulo Intelicon despliega una interfaz que permite la creación de reglas lógico-algebraicas de la forma:',
    moduleIntelicon_3: '• (Variable) Operador (Variable)  por ejemplo A+B',
    moduleIntelicon_4: '• Operador(Variable)',
    moduleIntelicon_5: 'Y su correspondiente evaluación en la entrada con la aplicación de operadores de comparación condicionales (igual, menor, mayor, En, No, entre otros)',
    moduleIntelicon_6: 'Para el caso de las reglas de salida, lo que en general se hace es almacenar el resultado de la expresión o regla en una variable nueva que se entrega a la salida del modulo de manera que este resultado podrá ser almacenado o bien escrito en la entrada de otra regla.',
    moduleSkeleton: 'Módulo Skeleton',
    moduleSkeleton_1: 'El modulo Skeleton permite la construcción de las estructuras de dato subyacentes en los orígenes de datos identificados para el sistema de captura. Es decir, si lo que se desea procesar es una serie de archivos Excel que representan balances por ejemplo, la aplicación de Skeleton debe recibir como entrada esos Excel, luego identificará las columnas y los datos estructurados en cada planilla, ofreciendo una propuesta de tablas para base de datos SQL y finalmente permitirá la creación del script de creación de la correspondiente base de datos.',
    moduleSkeleton_2: 'Skeleton utiliza el paradigma proyecto- modulo para identificar por una parte la naturaleza de los datos a capturar y poder agrupar estos datos en clases llamadas módulos. ',
    moduleSkeleton_3: 'Por ejemplo, el análisis financiero podría constituir un proyecto de Análisis de EEFF, con distintas especies o clases de datos a analizar, como los son los libros contables o los auxiliares, los que en este caso serán los distintos módulos en Skeleton.',
    moduleSkeleton_4: 'Lo relevante de este modulo es la posibilidad de crear las bases de dato del sistema final, de manera muy rápida y breve solo basándose en las fuentes de información nativas del caso a implementar.',
    moduleMonitor: 'Módulo Monitor',
    moduleMonitor_1: 'El modulo monitor es un sistema de gestión y administración de los componentes de datos del sistema generado por los otros módulos. Este módulo permite la asignación y creación de perfiles, roles, recursos y usuarios así como la asignación de los distintos roles a los usuarios. También se cuenta en este modulo con herramientas de auditoria del sistema generado, como por ejemplo los accesos al sistema, las acciones realizadas por los usuarios, los procesos en ejecución entre otros.',
    moduleMonitor_2: 'En definitiva el modulo Monitor es un conjunto de funcionalidades que permite orquestar y controlar el sistema generado. La instanciación de este módulo genera código solido que viene a formar parte del sistema final.',
    aboutLicence: 'Sobre el licenciamiento',
    licence_1: 'GrisMonitor es un conjunto de herramientas que permiten la generación de una o mas plataformas, por lo tanto la licencia que el cliente adquiere es la licencia de esos módulos para diseño y creación de las plataformas.',
    licence_2: 'Una vez generada la salida de cada modulo y hecho el deploy, será posible customizar o mejorar los sistemas generados realizando los ajustes finos entre las piezas obtenidas de manera que el cliente pueda finalmente contar con un sistema robusto y estable en muy breve plazo.',
    licence_3: 'El sistema resultante es de código abierto, es decir no existen restricciones de licenciamiento para las construcciones que son el resultado de la aplicación de GrisMonitor',
    licence_4: 'Eventualmente el cliente podría requerir integrarse con otras piezas de software de terceros, en este caso el costo de esas licencias corre por cuenta del cliente.',
    licence_5: 'Para configurar y poner en operación un sistema basado en estas herramientas, se requiere la realización de los siguientes pasos:',
    licence_5_1: '1.	Preparación de los servidores según las especificaciones de requerimientos mínimos establecidas para el caso técnico a implementar',
    licence_5_2: '2.	Habilitación de los permisos de red para facilitar los accesos desde la Web al servidor',
    licence_5_3: '3.	Instalar el software servidor con la licencia adquirida',
    licence_5_4: '4.	Asegurarse de contar con los permisos a los distintos orígenes de dato',
    licence_5_5: '5.	Acceder con la cuenta de developer GrisMonitor para configurar el sistema en cada modulo',
    licence_5_6: '6.	Hacer el deploy del sistema una vez realizadas las distintas configuraciones',
    licence_5_7: '7.	Desarrollar las adecuaciones al sistema generado',
    licence_5_8: '8.	Pasar a producción el sistema',
    licence_5_9: '9.	Monitorear y auditar la operación',
    characteristicsUse: 'Características del uso de Gris Monitor',
    characteristics_1: 'Este tipo de software asume que el cliente posee un proyecto o necesidad particular de negocio que puede ser resuelta en la lógica de la captura de datos, procesamiento y publicación de información. Ejemplos de uso del software Gris Monitor son los siguientes:',
    characteristics_2: 'Validación de licitaciones: en términos muy generales un proceso de licitación requiere un conjunto de información o datos que deben cumplir ciertas condiciones. Según el grado de cumplimiento de esas condiciones, se realiza una asignación de puntaje, siendo el más alto el seleccionado. ',
    characteristics_3: 'Validación de información financiero contable: gran parte de la auditoria financiero-contable requiere la comprobación y cuadratura de diversas cuentas que se resumen en los balances y estados de resultado. Estos libros contables o libros auxiliares son archivos (extensos en algunos casos) que contienen los datos a ser validados y contrastados por la vía de fórmulas de cálculo, respecto de las cifras globales de un balance por ejemplo (el balance será otro archivo con datos, sobre los cuales se aplican reglas de validación de integridad y de relación con el detalle de los auxiliares)',
    characteristics_4: 'Cumplimiento ambiental: las empresas que impactan el medioambiente deben monitorear periódica y continuamente los diversos impactos al medioambiente según lo establecido por la Superintendencia de Medioambiente. El monitoreo de material particulado en las emisiones, por ejemplo, constituye una regla de valor umbral que debe ser cumplido. Los datos o archivos de datos a validar con el detalle de esas emisiones pueden ser capturado y validado según las reglas emanadas de la norma. Lo interesante es que, si la norma cambia, como fue el caso del material particulado en suspensión, bastará con cambiar la regla definida, sin ser necesario modificar código de ningún programa.',
    characteristics_5: 'Gris Monitor permite automatizar procesos de este y otros tipos. La premisa es que los datos siempre pueden ser validados, estandarizados, analizados, transformados, clasificados, combinados para formar otro tipo de datos y producir información relevante al proyecto de negocio especifico atendido.',
    characteristics_6: 'Los datos pueden ser obtenidos de diversas fuentes, tales como: archivos Excel o “csv”, archivos de texto, PDF editable, XML y HTML.                    ',
    characteristics_7: 'Gris Monitor servidor permite la realización de los siguientes procesos principales:',
    characteristics_7_1: '•	Gestión de almacenes de datos de acuerdo al proyecto definido por el cliente o según sus necesidades particulares',
    characteristics_7_2: '•	Gestión de las reglas que se utilizaran sobre los datos que constituyen la estructura del almacén y de las fuentes de información relativas al proyecto',
    characteristics_7_3: '•	Gestión de las distintas interfaces de salida de información',
    characteristics_7_4: '•	Gestión de los procesos asociados al proyecto',
    characteristics_7_5: '•	Generación de reportes y bases de dato de análisis',
    characteristics_7_6: '•	Gestión de la operación y auditoria del proyecto de datos creado en Gris Monitor',
    contactForVisit: 'Contáctenos para una visita',
    contact_1: 'Si frecuentemente sus soluciones informáticas están siendo modificadas porque cambió la normativa, porque la ley se ha actualizado o por que las condiciones del entorno sufren cambios, le ofrecemos una solución definitiva con el software Gris Monitor.',
  }
}