import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import CajaTituloIzquierda from '../../components/CajaTituloIzquierda'

const Contacto = () => {
  return (
    <Col xs={12} className='pr-lg-5'>
      <Row className='pb-5' id='fila-contacto'>
        <CajaTituloIzquierda
          titulo={Dictionary.contact}
          idCaja='contacto'
          classCaja='px-lg-4'
          classHeader='px-lg-4'
        />
        <Col xs={12} lg={7} className='p-lg-5' id='formulario-contacto'>
          <Row className='px-5'>
            <form action='https://formspree.io/info@binarybag.com' method='POST' className='w-100' id='formContacto'>
              <div className='form-label-group'>
                <input type='text' name='name' id='name' className='form-control form-control-lg' placeholder='Nombre' required autoFocus={true} />
                <label htmlFor='name' className='form-control-placeholder'>{Dictionary.name}</label>
              </div>
              <div className='form-label-group'>
                <input type='email' name='_replyto' id='email' className='form-control form-control-lg' placeholder='Email' required />
                <label htmlFor='email' className='form-control-placeholder'>{Dictionary.email}</label>
              </div>
              <div className='form-label-group'>
                <input type='text' name='_subject' id='tema' className='form-control form-control-lg' placeholder='Tema' required />
                <label htmlFor='tema' className='form-control-placeholder'>{Dictionary.about}</label>
              </div>
              <div className='form-label-group'>
                <textarea name='message' id='message' rows='3' className='form-control py-4' placeholder='Mensaje' required />
                <label htmlFor='message' className='form-control-placeholder'>{Dictionary.message}</label>
              </div>
              <div className='row justify-content-center pt-3 pb-5' id='send-button'>
                <input type='submit' value={Dictionary.send} id='submit' className='btn' />
              </div>
            </form>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const ContactoContainer = () => {
  return (
    <RowContenedor
      tipo='contacto'
      componente={Contacto}
    />
  )
}

export default ContactoContainer
