import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import portadaLogo from '../../assets/images/img-web/portada_logo.png'
import think from '../../assets/images/img-web/think.png'
import RowContenedor from '../../components/RowContenedor'

const Inicio = () =>{
  return (
    <Row className='align-items-end' id='fila-presentacion'>
      <Col lg={6} xs={12} className='order-lg-2 pl-lg-5 mb-5 mb-lg-0'>
        <Row id='imagen-presentacion'>
          <img src={portadaLogo} alt='Logo' className='pl-lg-4' id='img-presentacion' />
          <div className='top-left-image-text d-none d-lg-block'>{Dictionary.welcome_to}</div>
        </Row>
      </Col>
      <Col lg={6} xs={12} className='order-lg-1'>
        <Row className='justify-content-center' id='caja-presentacion'>
          <Col lg={5} xs={12} className='d-flex justify-content-center align-items-center p-3 p-lg-5' id='think-col'>
            <img src={think} id='think-image' alt='think' />
          </Col>
          <Col lg={7} xs={12} className='d-flex justify-content-center align-items-end'>
            <p className='texto-presentacion px-4 my-5'>
              {Dictionary.we_are}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const InicioContainer = () => {
  return (
    <RowContenedor
      tipo='presentacion'
      componente={Inicio}
    />
  )
}

export default InicioContainer
