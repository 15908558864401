import React from 'react'
import { Route, Switch } from 'react-router-dom'
import '../../assets/css/style.css'
import InicioContainer from '../../containers/InicioContainer'
import TiposPlatamormaContainer from '../../containers/TiposPlataformaContainer'
import QueEsContainer from '../../containers/QueEsContainer'
import LicenciamientoContainer from '../../containers/LicenciamientoContainer'
import CaracteristicasContainer from '../../containers/CaracteristicasContainer'
import ContactoContainer from '../../containers/ContactoContainer'

const Inicio = () => <InicioContainer />
const TiposPlataforma = () => <TiposPlatamormaContainer />
const QueEs = () => <QueEsContainer />
const Licenciamiento = () => <LicenciamientoContainer />
const Caracteristicas = () => <CaracteristicasContainer />
const Contacto = () => <ContactoContainer />

const SwithRouter = () => {
  return (
    <Switch basename='app/webroot/seat/'>
      <Route path='/app/webroot/seat/' exact component={Inicio} />
      <Route path='/app/webroot/seat/inicio' exact component={Inicio} />
      <Route path='/app/webroot/seat/tipos-plataforma' exact component={TiposPlataforma} />
      <Route path='/app/webroot/seat/que-es' exact component={QueEs} />
      <Route path='/app/webroot/seat/licenciamiento' exact component={Licenciamiento} />
      <Route path='/app/webroot/seat/caracteristicas' exact component={Caracteristicas} />
      <Route path='/app/webroot/seat/contacto' exact component={Contacto} />
      <Route component={Inicio} />
    </Switch>
  )
}

export default SwithRouter
