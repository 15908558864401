import React from 'react'

const Parrafo = (props) => {
  const { subtitulo, lineas } = props
  const listedLineas = lineas.map((linea,index) => 
    <p key={`index-${index}`} className='mb-0' dangerouslySetInnerHTML={{__html: linea}} />
  )
  return (
    <div className="texto-presentacion px-4 mt-3 mb-3">
      {subtitulo !== undefined && (
        <b>{subtitulo}</b>
      )}
      {lineas !== undefined && (listedLineas)}
    </div>
  )
} 
export default Parrafo
