import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import diagrama from '../../assets/images/flujo/gris-monitor-diagrama.png'
import flujo from '../../assets/images/flujo/guia-flow.png'
import Parrafo from '../../components/Parrafo'

const QueEs = () => {
  const lineasModuleResult = [
    Dictionary.moduleResult_1,
    Dictionary.moduleResult_1_1,
    Dictionary.moduleResult_1_2,
    Dictionary.moduleResult_1_3,
    Dictionary.moduleResult_1_4,
    Dictionary.moduleResult_1_5,
    Dictionary.moduleResult_1_6,
    Dictionary.moduleResult_2,
    Dictionary.moduleResult_3,
    Dictionary.moduleResult_4,
    Dictionary.moduleResult_5
  ]
  const lineasModuleIntelicon = [
    Dictionary.moduleIntelicon_1,
    Dictionary.moduleIntelicon_2,
    Dictionary.moduleIntelicon_3,
    Dictionary.moduleIntelicon_4,
    Dictionary.moduleIntelicon_5,
    Dictionary.moduleIntelicon_6
  ]
  const lineasModuleSkeleton = [
    Dictionary.moduleSkeleton_1,
    Dictionary.moduleSkeleton_2,
    Dictionary.moduleSkeleton_3,
    Dictionary.moduleSkeleton_4
  ]
  const lineasModuleMonitor = [Dictionary.moduleMonitor_1, Dictionary.moduleMonitor_2]
  return (
    <Row className='align-items-end' id='fila-quees'>
      <Col xs={12}>
        <Row className='justify-content-center caja-container' id='caja-quees'>
          <h1 className='mt-3'>{Dictionary.titleWhatIs}</h1>
          <p className='texto-presentacion px-4 mt-3'>
            {Dictionary.whatIs_1}
            <br />GRISMonitor = <b>G</b>uiaFlow + <b>R</b>esult + <b>I</b>ntelicon + <b>S</b>keleton + <b>Monitor</b>
            <br />
            <img className='my-3' style={{maxWidth: '700px'}} src={diagrama} alt='diagrama' />
            <br />{Dictionary.whatIs_3}
            <br />{Dictionary.whatIs_4}
          </p>
          <p className='texto-presentacion px-4'>
            <b>{Dictionary.moduleFlow}</b>
            <br />{Dictionary.moduleFlow_1}
            <br />{Dictionary.moduleFlow_2}
            <br />
            <img className='my-3' src={flujo} alt='flujo' />
            <br />{Dictionary.moduleFlow_3}
            <br />
            <br />{Dictionary.moduleFlow_4}
            <br />{Dictionary.moduleFlow_5}
          </p>
          <Parrafo
            subtitulo={Dictionary.moduleResult}
            lineas={lineasModuleResult}
          />
          <Parrafo
            subtitulo={Dictionary.moduleIntelicon}
            lineas={lineasModuleIntelicon}
          />
          <Parrafo
            subtitulo={Dictionary.moduleSkeleton}
            lineas={lineasModuleSkeleton}
          />
          <Parrafo
            subtitulo={Dictionary.moduleMonitor}
            lineas={lineasModuleMonitor}
          />
        </Row>
      </Col>
    </Row>
  )
}

const QueEsContainer = () => {
  return (
    <RowContenedor
      tipo='quees'
      componente={QueEs}
    />
  )
}

export default QueEsContainer