import React from 'react'
import Row from 'react-bootstrap/Row'

const CajaTituloIzquierda = (props) => {
  const {
    titulo,
    idCaja,
    classCaja,
    classHeader
  } = props
  return (
    <div className='col-12 col-lg-5'>
      <Row className={`justify-content-center align-items-center px-4 ${classCaja}`} id={`caja-${idCaja}`}>
        <h1 className={`header ${classHeader} pb-lg-2 pt-lg-4`}>{titulo}</h1>
      </Row>
    </div>
  )
}

export default CajaTituloIzquierda
