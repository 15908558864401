import React from 'react'

const RowContenedor = (props) => {
  const {
    tipo,
    componente: Componente
  } = props
  return (
    <div className='tab-content'>
      <div className='w-100' id={`container-${tipo}`}>
        <div className='container-fluid'>
          <Componente />
        </div>
      </div>
    </div>
  )
} 

export default RowContenedor
