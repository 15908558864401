export default [
  {imagen: 'suseso.jpg', ancho: '85%'},
  {imagen: 'cchc.svg', ancho: '75%'},
  {imagen: 'ist.jpeg', ancho: '75%'},
  {imagen: 'deloitte.png', ancho: '70%'},
  {imagen: 'ecertchile.png', ancho: '70%'},
  {imagen: 'ascc.svg', ancho: '70%'},
  {imagen: 'codelco.png', ancho: '70%'},
  {imagen: 'sicpa.png', ancho: '70%'},
  {imagen: 'sernac.jpg', ancho: '90%'},
  {imagen: 'anglo-american.png', ancho: '70%'},
  {imagen: 'energia.jpg', ancho: '70%'},
  {imagen: 'cne.jpg', ancho: '70%'},
  {imagen: 'isl.jpg', ancho: '70%'},
  {imagen: 'bci.png', ancho: '70%'},
  {imagen: 'indra.png', ancho: '70%'},
  {imagen: 'atentus.png', ancho: '90%'},
  {imagen: 'fcfm.png', ancho: '100%'},
  {imagen: 'sag.jpg', ancho: '70%'},
  {imagen: 'chilecompra.jpg', ancho: '70%'},
  {imagen: 'pronto-copec.jpg', ancho: '70%'},
  {imagen: 'lider.svg', ancho: '70%'},
  {imagen: 'nic.jpeg', ancho: '90%'}
]
