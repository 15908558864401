import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dictionary from '../../components/Dictionary'
import RowContenedor from '../../components/RowContenedor'
import Parrafo from '../../components/Parrafo'

const Licenciamiento = () => {
  const lineasLicence = [
    Dictionary.licence_1,
    Dictionary.licence_2,
    Dictionary.licence_3,
    Dictionary.licence_4,
    Dictionary.licence_5,
    Dictionary.licence_5_1,
    Dictionary.licence_5_2,
    Dictionary.licence_5_3,
    Dictionary.licence_5_4,
    Dictionary.licence_5_5,
    Dictionary.licence_5_6,
    Dictionary.licence_5_7,
    Dictionary.licence_5_8,
    Dictionary.licence_5_9
  ]
  return (
    <Row className='align-items-end' id='fila-licenciamiento'>
      <Col xs={12}>
        <Row className='justify-content-center caja-container' id='caja-licenciamiento'>
          <h1 className='mt-3'>{Dictionary.aboutLicence}</h1>
          <Parrafo lineas={lineasLicence} />
        </Row>
      </Col>
    </Row>
  )
}

const LicenciamientoContainer = () => {
  return (
    <RowContenedor
      tipo='licenciamiento'
      componente={Licenciamiento}
    />
  )
}

export default LicenciamientoContainer
