import React from 'react'
import Col from 'react-bootstrap/Col'

const ImagenCliente = (props) => {
  const {
    imagen,
    widthImage
  } = props
  const Imagen = require(`../../assets/images/img-clientes/${imagen}`)
  return (
    <Col xs={12} md={6} lg={4}>
      <img className='mx-auto d-block py-4' src={Imagen} style={{width: widthImage}} alt={imagen} />
    </Col>
  )
}

export default ImagenCliente
