import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import AppRouter from './components/AppRouter'

const ToRender = () => (
  <AppRouter />
)

ReactDOM.render(
  <ToRender />,
  document.getElementById('root')
)
